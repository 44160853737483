import { useRouter } from 'next/router';
import Button from '@mui/material/Button';
import Image from 'next/image';

export default function Http404() {
    const router = useRouter();

    const handleLogin = (event: any) => {
        event.preventDefault();
        router.push('/login');
    };

    return (
        <div className="main-wrapper error">
            <div className="introduction0">
                <div className="logo">
                    <Image src="/assets/images/logo.svg" alt="logo" sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw" fill priority />
                </div>
                <h2 className="page-title">ページが見つかりませんでした。</h2>
                <p className="page-detail">
                    申し訳ございませんが、お探しのページが見つかりませんでした。
                    <br />
                    お探しのページは削除されたか、URLが変更された可能性がございます。
                </p>
                <div className="button-field">
                    <Button type="button" size="large" variant="contained" fullWidth className="gototop" onClick={handleLogin}>
                        ログインページへ
                    </Button>
                </div>
            </div>
        </div>
    );
}
